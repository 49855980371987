<template>
  <div>
    <Assistant_teacher/>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงานการประเมินครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="6" class="text-right">
           <v-btn href="#/admin/print_AssismentReport/" target="_blank">พิมพ์รายงานผลการประเมิน</v-btn>
            </v-col>

          </v-row>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="assisPersonel"
          class="elevation-1"
          :search="search"
        >
           <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"    
              @click="personnel_temporaryEdit(item.id_card)"       
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.cancels="{ item }">
            <v-icon color="red" @click="personnel_temporaryDelete(item.id_card)">
              mdi-cancel
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      

        <!-- V-model deletepersonnel_temporarydialog -->
        <v-layout>
        <v-dialog
          v-model="deletepersonnel_temporarydialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลผู้ใช้"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletepersonnel_temporaryform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลผู้ใช้
                          {{ editpersonnel_temporary.college_code }}
                          <span v-if="editpersonnel_temporary.college_name">{{
                            editpersonnel_temporary.college_name
                          }}</span>
                          <div>
                            {{ editpersonnel_temporary.title_s
                            }}{{ editpersonnel_temporary.frist_name }}
                            {{ editpersonnel_temporary.last_name }}
                          </div>
                          <div>
                            {{ editpersonnel_temporary.position_name }} :
                            {{ editpersonnel_temporary.id_position }}
                          </div>
                        </v-flex>
                        <v-text-field
                          filled
                          rounded
                          type="password"
                          label="Password"
                          :rules="[v => v === '0612698358']"
                        >
                        </v-text-field>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletepersonnel_temporarydialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletepersonnel_temporarySubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

    </v-container>
  </div>
</template>
<script>
import Assistant_teacher from './assistant_teacher.vue';

export default {
    data() {
        return {
            ApiKey: "HRvec2021",
            valid: true,
            loading: true,
            editpersonnel_temporarydialog: false,
            deletepersonnel_temporarydialog: false,
            editpersonnel_temporary: [],
            search: "",
            headers: [
                { text: "เลือก", align: "left", value: "id_card" },
                { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
                { text: "สถานศึกษา", align: "left", value: "college_name" },
                { text: "ID", align: "left", value: "id_card" },
                { text: "ชื่อ-นามสกุล", align: "left", value: "fristName" },
                { text: "เลขที่", align: "left", value: "id_position" },
                { text: "วันที่บรรจุ", align: "left", value: "date_app_now" },
                { text: "คำสั่งที่", align: "left", value: "order_app_now" },
                { text: "วดป.1", align: "left", value: "assisDay1" },
                { text: "คะแนน 1", align: "left", value: "assis1" },
                { text: "วดป.2", align: "left", value: "assisDay2" },
                { text: "คะแนน 2", align: "left", value: "assis2" },
                { text: "วดป.3", align: "left", value: "assisDay3" },
                { text: "คะแนน 3", align: "left", value: "assis3" },
                { text: "วดป.4", align: "left", value: "assisDay4" },
                { text: "คะแนน 4", align: "left", value: "assis4" },
                { text: "คะแนน รวม", align: "left", value: "sumScore" },
                {
                    text: "แก้ไข",
                    align: "center",
                    value: "actions",
                    icon: "mdi-file-document-edit"
                },
                {
                    text: "ยกเลิกการประเมิน",
                    align: "center",
                    value: "cancels",
                    icon: "mdi-cancel"
                }
            ],
            assisPersonel: [],
            rowsperpage: [
                25,
                50,
                100,
                {
                    text: "All",
                    value: -1
                }
            ]
        };
    },
    async mounted() {
        /*  await this.sweetAlertLoading();
         await this.assisPersonelQuery();
         Swal.close(); */
    },
    methods: {
        async assisPersonelQuery() {
            this.loading = true;
            let result = await this.$http
                .post("assistant_personelReport.php", {
                ApiKey: this.ApiKey
            })
                .finally(() => (this.loading = false));
            this.assisPersonel = result.data;
            ;
        },
        async sweetAlertLoading() {
            Swal.fire({
                title: "Please Wait !",
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
                onAfterClose: () => {
                    Swal.hideLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        },
        async personnel_temporaryEdit(id_card) {
            let result = await this.$http.post("personnel_temporary.php", {
                ApiKey: this.ApiKey,
                id_card: id_card
            });
            this.editpersonnel_temporary = result.data;
            this.editpersonnel_temporary.p_word = "";
            this.editpersonnel_temporarydialog = true;
        },
        async editpersonnel_temporarySubmit() {
            if (this.$refs.editpersonnel_temporaryform.validate()) {
                this.editpersonnel_temporary.ApiKey = this.ApiKey;
                if (this.editpersonnel_temporary.p_word == "")
                    delete this.editpersonnel_temporary.p_word;
                if (this.editpersonnel_temporary.rang_condition == null)
                    this.editpersonnel_temporary.rang_condition = "0";
               
                let result = await this.$http.post("personnel_temporary.update.admin.php", this.editpersonnel_temporary);
                if (result.data.status == true) {
                    this.personnel_temporary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.personnel_temporaryQueryAll();
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "update";
                    this.data_syslog.page_log = "personnel_temporary";
                    this.data_syslog.table_log = "personnel_temporary";
                    this.data_syslog.detail_log = this.personnel_temporary.id_card;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.editpersonnel_temporarydialog = false;
            }
        },
        async personnel_temporaryDelete(id_card) {
            let result = await this.$http.post("personnel_temporary.php", {
                ApiKey: this.ApiKey,
                id_card: id_card
            });
            this.editpersonnel_temporary = result.data;
            this.deletepersonnel_temporarydialog = true;
        },
        async deletepersonnel_temporarySubmit() {
            if (this.$refs.deletepersonnel_temporaryform.validate()) {
                this.editpersonnel_temporary.ApiKey = this.ApiKey;
                let result;
                result = await this.$http.post("personnel_temporary.delete.php", this.editpersonnel_temporary);
                if (result.data.status == true) {
                    this.personnel_temporary = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการลบข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.personnel_temporaryQueryAll();
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "delete";
                    this.data_syslog.page_log = "personnel_temporary";
                    this.data_syslog.table_log = "personnel_temporary";
                    this.data_syslog.detail_log = this.personnel_temporary.id_card;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการลบผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.deletepersonnel_temporarydialog = false;
            }
        },
    },
    computed: {
        pages() {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null)
                return 0;
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },
        date_today() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            today = dd + "/" + mm + "/" + yyyy;
            return today;
        },
        color() {
            return "indigo darken-4";
        },
        date_today_log() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = dd + "/" + mm + "/" + yyyy + "/" + time;
            return today;
        }
    },
    components: { Assistant_teacher }
};
</script>
